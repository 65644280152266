<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					variant="success"
					class="float-right"
					@click="exportGuiInformation()">
					<i
						class="fas fa-file-download"
						aria-hidden="true" />
					{{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template #form>
				<div class="row">
					<div
						:class="'col-md-4'"
						class="col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							class="border-top-0">
							{{ translate('order_no') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('order_date') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('refund_date') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('username') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('resident_id') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('app_form_status') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('app_form_received_date') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_to_name') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('corp_or_ind') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('order_type') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('product_name') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('qty') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('package') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('unit_price') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('price_total') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('tw_unit_price') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('tw_price_total') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_method') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('payment_method') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_address_1') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_address_2') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_city') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_state') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('phone') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('first_name_2') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('last_name_2') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('mailing_address_1') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('mailing_address_2') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('city') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('state') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('zip') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('email') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('cc_number_last_4') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle text-center">
							{{ item.attributes.order_no }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.order_date.date).format('DD/MM/YYYY HH:mm:ss') }}
						</td>
						<td class="align-middle">
							{{ item.attributes.refund_date }}
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.resident_id }}
						</td>
						<td class="align-middle">
							{{ item.attributes.app_form_status }}
						</td>
						<td class="align-middle">
							{{ item.attributes.app_form_received_date ? $moment(item.attributes.app_form_received_date.date).format('DD/MM/YYYY') : '' }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_to_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.corp_or_ind }}
						</td>
						<td class="align-middle">
							{{ item.attributes.order_type }}
						</td>
						<td class="align-middle">
							{{ item.attributes.product_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.qty }}
						</td>
						<td class="align-middle">
							{{ item.attributes.package }}
						</td>
						<td class="align-middle">
							{{ item.attributes.unit_price }}
						</td>
						<td class="align-middle">
							{{ item.attributes.price_total }}
						</td>
						<td class="align-middle">
							{{ item.attributes.tw_unit_price }}
						</td>
						<td class="align-middle">
							{{ item.attributes.tw_price_total }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_method }}
						</td>
						<td class="align-middle">
							{{ item.attributes.payment_method }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_address_1 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_address_2 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_city }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_state }}
						</td>
						<td class="align-middle">
							{{ item.attributes.phone }}
						</td>
						<td class="align-middle">
							{{ item.attributes.first_name_2 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.last_name_2 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.mailing_address_1 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.mailing_address_2 }}
						</td>
						<td class="align-middle">
							{{ item.attributes.city }}
						</td>
						<td class="align-middle">
							{{ item.attributes.state }}
						</td>
						<td class="align-middle">
							{{ item.attributes.zip }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							{{ item.attributes.cc_number_last_4 }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>

import { GuiInformation } from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import { DATE_RANGES as dateRanges } from '@/settings/Dates';
import Order from '@/util/Order';

export default {
	name: 'Orders',
	messages: [
		GuiInformation,
	],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams],
	data() {
		return {
			orders: new Order(),
			download: new Order(),
			dateRanges,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.dateRange = this.$route.query.dateRange ?? '';
		this.getOrders(this.getQuery());
	},
	methods: {
		getOrders(options = {}) {
			this.orders.getGuiInformation(false, options);
		},
		getQuery() {
			const { query } = this.$route;
			return { ...query, ...this.filters };
		},
		getDataFiltered() {
			this.$router.push({ name: 'GUIInformation', query: this.getQuery() }).catch(() => {});
		},
		exportGuiInformation() {
			this.download.getGuiInformation(true, this.getQuery()).then(() => {
				const { response } = this.download.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				const filename = `${this.translate('gui_information')}.csv`;
				link.setAttribute('download', filename);
				this.$el.appendChild(link);
				link.click();
			});
		},
	},
};
</script>
